<template>
  <v-app id="inspire">
    <div class="header">
      <v-btn class="choose-Status">Choose Status</v-btn>
    <v-form @submit.prevent class="form-Submit">
      <v-btn class="mt-2" type="submit" id="Submit" v-model="statusTodoFilter" @click="filterStatusTodo()">TODO</v-btn>
      <v-btn class="mt-2" type="submit" id="Submit-CompleteAirdrop" v-model="statusComplete" @click="filterStatusComplete()">COMPLETE</v-btn>
      <v-btn class="mt-2" type="submit" id="Submit-AllAirdrop" @click="callAPI()">All Airdrop</v-btn>
    </v-form>
    </div>
    <div class="body">
       <v-btn @click="exportDataToCSV()" class="download-airdrop">Dowload</v-btn>
      <v-list-item v-for="(item) in dataList" :key="item.id" class="list-data">
          <template >
            <v-list-item-content>
              <v-list-item-title>{{item.todo}}</v-list-item-title>
            </v-list-item-content>
          <v-list-item-content>
              <a :href="item.link">link-game</a>
            </v-list-item-content>
              <v-list-item-content>
              <v-list-item-title >
                <v-btn @click="changeStatus(item.id)">{{item.status}}</v-btn>
                </v-list-item-title>
            </v-list-item-content>
          </template>
      </v-list-item>
      </div>
      <v-img
      class="img-elon-musk"
      max-height="400px"
      max-width="800px"
      src="../assets/elon-musk.jpg"
      ></v-img>
  </v-app>
</template>
<script>
import axios from'axios'
  export default {
    name:'Table-item',
    data () {
      return {
      statusTodo:'Todo',
      statusComplete:'Complete',
      addDataTodo: '',
      dataList:[],
      popupHide:false,
      todoEditContent:'',
      idEditContent:null
      }
    },
    watch:{
    },
    created(){
        this.callAPI()
    },
    mounted(){
    },
    methods:{
    exportDataToCSV(){
      const data = this.dataList
      const csvContent = this.convertToCSV(data);
      const blob = new Blob([csvContent],{type : 'text/csv; charset=utf-8'})
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download','export_data.csv')
      link.click();
    },
    convertToCSV(data){
      const headers = Object.keys(data[0]) 
      const rows = data.map(obj => headers.map(header => obj[header]))
      const headerRow = headers.join(',')
      const csvRows =[headerRow, ...rows.map(row => row.join(','))]
      return csvRows.join('\n')

    },
    changeStatus(id){
           this.dataList.find(item =>{
           if(item.id === id){
           if (item.status ==="Todo"){
            item.status ="Complete"
            
           }
           else{
            item.status ="Todo"
           }
            const updateStatus ={
            status:item.status,
            todo:item.todo  
          }
            axios.put(`https://54.241.120.85:3000/api/v1/lists/${id}`,updateStatus)
            .then(() => {
          });
           }
          }
          )
          },
    callAPI(){
            let dataTodo = []
             axios.get('https://54.241.120.85:3000/api/v1/lists').then(res =>{
                res.data.map(item =>{
                    dataTodo.push(item)
                    this.dataList = dataTodo
                    }
                )
            }   
            )
        } ,
    filterStatusTodo(){
          let dataTodo = []
          const item ={
            status:this.statusTodo
          }          
          axios.post(`https://54.241.120.85:3000/api/v1/lists/search/status`,item)
          .then(res =>{
                res.data.map(item =>{
                    dataTodo.push(item)
                    this.dataList = dataTodo
                    }
                )
            }   
            )
            .catch(
              error =>{
                console.log(error);
                
              }
            )
        },
    filterStatusComplete(){
          let dataTodo = []
          const item ={
            status:this.statusComplete
          }          
          axios.post(`https://54.241.120.85:3000/api/v1/lists/search/status`,item)
          .then(res =>{
                res.data.map(item =>{
                    dataTodo.push(item)
                    this.dataList = dataTodo
                    }
                )
            }   
            )
            .catch(
              error =>{
                console.log(error);
                
              }
            )
        },
    }
  }
</script>
<style scoped>
.choose-Status{
  margin-top: 25px;
  margin-bottom: 25px;
}
#Submit-CompleteAirdrop{
  margin-left: 10px;
}
#Submit-AllAirdrop{
  margin-left: 10px;
}
.download-airdrop{
  margin-top: 55px;
}
.img-elon-musk{
  margin: auto;
}
button.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
    width: 180px;
}

.list-data{
  margin: auto;
  display: flex;
  align-items:center;
}
.form-Submit{
  margin: auto;
  display: flex;
}
.v-input__control{
  width: 50%!important;
}
.body{
  line-height: 5px ;
}
.v-list-item__title {
    display: flex;
}
.handleTodo{
  margin-left: 125px ;
}
</style>