<template>
  <div>
    <h1 id="todo-list">Airdrop Crypto - Do that because it free</h1>
    <Table/>
  </div>
</template>
<script>
import Table from './components/Table.vue'
  export default {
  components: { Table },
    data () {
      return {
      }
    },
  }
</script>
<style scoped>
#todo-list{
  background: yellow;
  align-content: center;
  text-align: center;
  margin:auto
}
</style>
